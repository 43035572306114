<template>
	<page>
		<module enable-flex>
			<div class="main flex-grow" @click="tips"></div>
		</module>
	</page>
</template>

<script>
	export default {
		methods: {
			tips() {
				this.$confirm({
					text: '功能开发中，敬请期待',
					buttonName: '我知道了',
					cancelButton: false
				});
			}
		}
	}
</script>

<style lang="stylus" scoped>
	.main {
		background-image: url('~@/assets/image/statistic.jpg');
		background-size: cover;
		background-position: top center;
	}
</style>